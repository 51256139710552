export const menuDialogMap = [
  { present: "SignIn", code: "SignIn", visibleInAuth: false, dialogTitle:"Sign in to Datapadu", btnText: "SIGN IN", transitionDuration: 300,  },

  // { present: "Create new account", code: "SignUp", visibleInAuth: false  , dialogTitle:"Registration", btnText: "Create new account", transitionDuration: 300, },

  { present: "My Profile", code: "MyProfile", visibleInAuth: true, dialogTitle:"My Profile", dialogContentText: "Here is your profile", transitionDuration: 300, },

  { present: "Logout", code: "Logout", visibleInAuth: true, dialogTitle:"Logout", dialogContentText: "Do you really want to logout? ", transitionDuration: 300, },
];

export const menuPageMap = [
  { present: "Home", code: "Home", public: true},
  { present: "Products", code: "Products", public: true},
  { present: "Contact", code: "Contact", public: true},
  { present: "Dashboard", code: "Dashboard", public: false},

];
