import { Admin, Resource } from "react-admin";
import useDataProvider from "../hooks/useDataProvider";
import UserIcon from "@mui/icons-material/Group";
import { ProductsList, ProductsEdit, ProductsCreate } from "./Products";
import MyLayout from "./MyLayout";
import Dashboard from "./Dashboard";

export const StoreAdmin = () => {
  const dataProvider = useDataProvider();

  return (
    // <Admin basename="/products" dashboard={Dashboard} dataProvider={dataProvider}>
    <Admin basename="/products" dashboard={Dashboard} layout={MyLayout} dataProvider={dataProvider}>
      <Resource name="products"
        list={ProductsList}
        edit={ProductsEdit}
        create={ProductsCreate}
        icon={UserIcon}
      />
    </Admin>
  );
};
