import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  TextInput,
  SimpleForm,
  Create,
  useResourceContext,
} from "react-admin";
import { ProductsPagination } from "./ProductsPagination";

const ResourceName = () => {
  const resource = useResourceContext();
  console.log({ resource });
  return <>{resource}</>;
};

const ProductsFilters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <TextInput label="Color" source="color" />,
  <TextInput label="PK" source="PK" />,
];

export const ProductsList = () => (
  <List pagination={<ProductsPagination />} filters={ProductsFilters}>
    {/* </List><List pagination={<ProductsPagination/>} filters={ProductsFilters} disableAuthentication > */}
    <ResourceName />
    <Datagrid rowClick="edit">
      <TextField source="id" label="id" />
      <TextField source="PK" label="PK" />
      <TextField source="SK" label="SK" />
      <TextField source="price" />
      <TextField source="color" />
    </Datagrid>
  </List>
);

export const ProductsEdit = (props) => (
  <Edit>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="PK" label="PK" />
      <TextInput source="SK" label="SK" />
      <TextInput source="price" />
      <TextInput source="color" />
    </SimpleForm>
  </Edit>
);

export const ProductsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="PK" label="PK" />
      <TextInput source="SK" label="SK" />
      <TextInput source="price" />
      <TextInput source="color" />
    </SimpleForm>
  </Create>
);
