export const STEP = {
  SIGNIN: "SignIn",
  SIGNUP: "SignUp",
  VERIFICATION: "Verification",
  FORGOT_PASSWORD: "ForgotPassword",
  CONFIRM_FORGOT_PASSWORD: "ConfirmForgotPassword",
};

export const stepData = {
  SignIn: {
    step : STEP.SIGNIN,
    path: "/auth/signin",
    btnText: "Sign In",
    dialogTitle: "Sign in to Datapadu",
    info: "Please enter your credentials."
  },
  SignUp: {
    step : STEP.SIGNUP,
    path: "/auth/signup",
    btnText: "Create New Account",
    dialogTitle: "Registration ",
    info: "Please enter your account data."

  },
  Verification: {
    step : STEP.VERIFICATION,
    btnText: "Submit",
    dialogTitle: "Email Verification",
    path: "/auth/verify",
    resendCodePath : "/auth/resendcode",
    info: "Please insert your six digit code and hit enter to submit."

  },
  ForgotPassword: {
    step : STEP.FORGOT_PASSWORD,
    btnText: "Submit",
    dialogTitle: "Reset Password",
    path: "auth/forgotpwd",
    info: "Please enter your email address and you will receive verification code to confirm passwort reset."

  },
  ConfirmForgotPassword: {
    step : STEP.CONFIRM_FORGOT_PASSWORD,
    btnText: "Change Password",
    dialogTitle: "Confirm Reset Password",
    path: "auth/confirm-forgot-pwd",
    info: "Please enter your new password and verification code we have sent to your email."

  },
};

export const ALERT = {
  BLANK: "",
  SUCCESS_SIGNUP: "Please follow the instructions in the email sent to you.",
  SUCCESS_VERIFICATION: "Your email is verified. Now you can login.",
  SUCCESS_RESEND_CODE: "New verification code sent to your email.",
  SUCCESS_RESET_REQUEST: "A verification code for password reset sent to your email.",
  SUCCESS_PASSWORD_RESET: "Your password has been successfully reset. Please sign in with the new password",
  ERROR_INCORRECT_PWDUSR: "Incorrect username or password.",
  ERROR_CODE_MISMATCH: "Failed. Please check your code and try again.",
  ERROR_INPUT_VALIDATION: "Invalid inputs, please check again.",
  ERROR_PWD_ATT_EXC: "Password attempt exceeded.",
  ERROR_LIMIT_EXC: "Attempt limit exceeded, please try after some time.",
  ERROR_INVALID_TOKEN: "Verification code has been expired",
  CODE_COMPLETE: "Now hit enter to submit.",
  ERROR_NETWORK_ERROR : "Please check your internet connection"

};

export const RESPONSE = {
  SUCCESS_USR_NOT_CONFMD: "UserNotConfirmedException",
  ERROR_INCORRECT_PWDUSR: "IncorrectUsernameOrPassword",
  ERROR_CODE_MISMATCH: "CodeMismatchException",
  ERROR_INPUT_VALIDATION: "InputValidationFailed",
  ERROR_PWD_ATT_EXC: "PasswordAttemptsExceeded",
  ERROR_LIMIT_EXC: "LimitExceededException",
  ERROR_INVALID_TOKEN: "InvalidToken"
};

export const FORM = {
  NEED_ACCOUNT: "Need an account?",
  HAVE_ACCOUNT: "Already have an account?",
  PASSWORD_INFO: "8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character.",
  ENTER_PASSWORD: "Please enter your password",
  FIELD_REQUIRED: "This field is required",
  VALIDATION_PASSWORD: "Password does not meet the requirements",
  VALIDATION_EMAIL: "Please enter a valid Email",
  VALIDATION_EMAIL_REQ: "Not a valid email",
  VALIDATION_FULLNAME: "Please enter your full name" ,
  VALIDATION_VCODE : "Please enter 6 digit numbers",
  VALIDATION_RETYPE_PWD : "Please re-type password",
  VALIDATION_RETYPE_PWD_MISMATCH: "Password and retype password do not match"
};

