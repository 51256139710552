import React, {useContext} from "react";
import AuthContext from "../context/AuthProvider";

const Dashboard = () => {
  const authCtx = useContext(AuthContext);

  console.log("authCtx:", authCtx);
  return (
    <>
      {authCtx.auth.user && <p>Welcome {authCtx.auth.name} </p>}
      <br />
      <div>This is Dashboard Page</div>
    </>
  );
};

export default Dashboard;
