import { Layout } from "react-admin";
import MyAppBar from "./MyAppBar";
import { MyMenu } from './MyMenu';

const MyLayout = (props) => {

    console.log("props:", props)
  return (
    <Layout
      {...props}
      appBar={MyAppBar}
    />
  );
};

export default MyLayout;
