import { useState, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuItem from "@mui/material/MenuItem";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import { useNavigate } from "react-router-dom";
import DialogContext from "../context/DialogProvider";
import AuthContext from "../context/AuthProvider";
import { menuDialogMap, menuPageMap } from "../data/menu-map";
import useLogout from "../hooks/useLogout";

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const navigate = useNavigate();
  const logout = useLogout();

  const dialogCtx = useContext(DialogContext);
  const authCtx = useContext(AuthContext);

  console.log("NavBar called")

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClickNavMenu = (event, index) => {
    console.log("Menu Index :", index);
    console.log("Inner Text:", event.target.innerText);

    navigate(menuPageMap[index].code.toLowerCase(), { replace: true });
    setAnchorElNav(null);
  };

  const handleClickUserMenu = async (event, index) => {
    console.log("Menu Index :", index);
    console.log("Inner Text:", event.target.innerText);

    switch (index) {
      case 0:
        dialogCtx.setDialog({ open: true, menuIdx: index });
        break;
      // case 1:
      //   dialogCtx.setDialog({ open: true, menuIdx: index });
      //   break;
      case 1:
        navigate(menuDialogMap[index].code.toLowerCase(), { replace: true });
        break;
      case 2:
        setAnchorElUser(null);
        logout();
        navigate("/home");
        break;
      default:
        break;
    }

    console.log("exit from Profile");

    setAnchorElUser(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {menuPageMap.map((page, index) =>
              page.public || authCtx.auth?.user ? (
                <MenuItem
                  key={index}
                  onClick={(event) => handleClickNavMenu(event, index)}
                >
                  <Typography textAlign="center">{page.present}</Typography>
                </MenuItem>
              ) : null
            )}
          </Menu>
        </Box>

        <Box
          sx={{
            display: { xs: "flex", md: "inline" },
            justifyContent: "center",
            flexGrow: { xs: 1, md: 0 },
          }}
        >
          <SelfImprovementIcon sx={{ display: { md: "inline" }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { md: "inline" }, mr: 1 }}
          >
            Selfimpro
          </Typography>
        </Box>

        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "row",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          {menuPageMap.map((page, index) =>
            page.public || authCtx.auth?.user ? (
              <Button
                key={index}
                onClick={(event) => handleClickNavMenu(event, index)}
                sx={{ my: 2, color: "white" }}
                //   sx={{ my: 2, color: "white", display: "block" }}
              >
                {page.present}
              </Button>
            ) : null
          )}
        </Box>

        <Box>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar>
              <ManageAccountsIcon />
            </Avatar>
          </IconButton>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {menuDialogMap.map((dialog, index) =>
              (dialog.visibleInAuth && authCtx.auth?.user) ||
              (!dialog.visibleInAuth && !authCtx.auth?.user) ? (
                <MenuItem
                  key={index}
                  onClick={(event) => handleClickUserMenu(event, index)}
                >
                  <Typography textAlign="center">{dialog.present}</Typography>
                </MenuItem>
              ) : null
            )}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default NavBar;
