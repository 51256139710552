import axios from "axios";
const BASE_URL = "https://elb.datapadu.com";
// const BASE_URL = 'http://localhost:3500';

const mainHub = axios.create({
  baseURL: BASE_URL,
  timeout: 10*1000,
  headers: { "Content-Type": "application/json" },
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export default mainHub;
