import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import useRefreshToken from "../hooks/useRefreshToken";

const PersistLogin = () => {

  console.log("PersistLogin called");

  const {isLoading} = useRefreshToken({reload: true})

  return (
    <>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color="inherit" />
        </Box>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
