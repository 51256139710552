import * as React from 'react'

const DialogContext = React.createContext({ dialog: null, setDialog: () => {} });

export const DialogProvider = ({ children }) => {
  const [dialog, setDialog] = React.useState({ dialog: false });
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [messageSnackBar, setmessageSnackBar] = React.useState("");
  const [severity, setSeverity] = React.useState("success");

  return (
    <DialogContext.Provider value={{ dialog, setDialog, openSnackBar, setOpenSnackBar, messageSnackBar, setmessageSnackBar,severity,setSeverity }}>
      {children}
    </DialogContext.Provider>
  );
};

export default DialogContext;
