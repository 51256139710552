import { useQueryData } from "../hooks/useRQuery.js";
import { useSetAuthCtx } from "./useSetAuthCtx";

const LOGOUT_URL = "/auth/logout";

const useLogout = () => {
  const setAuthCtx = useSetAuthCtx();

  const { refetch:logout } = useQueryData({
    key: ["logout"],
    reqConfig: { method: "get", url: LOGOUT_URL, withCredentials: true  },
    enabled: false,
    onSettled: () => setAuthCtx(null),
  });

  return logout;
};

export default useLogout;
