import { useLocation, Navigate, Outlet } from "react-router-dom";
import {useContext } from "react";
import AuthContext from "../context/AuthProvider";

const RequireAuth = ({ allowedRoles }) => {
    const ctx = useContext(AuthContext);
    const location = useLocation();
    console.log("User logged-in:", ctx.auth.user, "ucogId:", ctx.ucogId)

    return (
        ctx.auth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : ctx.auth?.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/home" state={{ from: location }} replace />
    );
}

export default RequireAuth;