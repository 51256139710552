import { createPortal } from "react-dom";


function DialogTitlePortal({ title, node }) {

    console.log("DialogTitlePortal receives Title:", title);
    console.log("DialogTitlePortal Current Node :", node);
    let jsx = title;
    if (!node) {
      return null;
    }
    console.log("current Node :", node);
    return createPortal(jsx, node);
}

export default DialogTitlePortal