import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

export const useSetAuthCtx = () => {
  const authCtx = useContext(AuthContext);

  const setAuthCtx = (data) => {

    authCtx.setAuth({
      user: data?.data.user,
      name: data?.data.name,
      ucogId: data?.data.ucogId,
      roles: data?.data.roles,
      AccessToken: data?.data.AccessToken,
    });

    authCtx.setUcogId(data?.data.ucogId);
    data? localStorage.setItem("ucogId", data?.data.ucogId) : localStorage.removeItem("ucogId");
  };

  return setAuthCtx;
};
