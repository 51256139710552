import * as React from "react";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import DialogContext from "../context/DialogProvider";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import mainHub from "../api/mainHub";
import { useMutation } from "react-query";

import { useNavigate, useLocation } from "react-router-dom";
import { useSetAuthCtx } from "../hooks/useSetAuthCtx";
import DialogTitlePortal from "./DialogTitlePortal";
import OtpInput from "react18-input-otp";
import "./OtpInput.css";
import { STEP, ALERT, RESPONSE, FORM, stepData } from "./SignFormData";

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const CODE_REGEX = /^[0-9]{6,6}$/;

const SignForm = (props) => {
  console.log("props new", props);

  const [alert, setAlert] = React.useState("");
  const [step, setStep] = React.useState(STEP.SIGNIN);
  const [forgotPwdToken, setForgotPwdToken] = React.useState("");
  const dialogCtx = React.useContext(DialogContext);

  const setAuthCtx = useSetAuthCtx();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  const {
    handleSubmit,
    control,
    setFocus,
    clearErrors,
    resetField,
    unregister,
    getValues,
    reset: resetForm,
  } = useForm({
    defaultValues: {
      Fullname: "",
      Email: "",
      Password: "",
      ConfirmPassword: "",
      Vcode: "",
    },
    mode: "onSubmit",
    reValidateMode: "onChange",
    delayError: 500,
  });

  const isValueEqual = (value) => {
    const valOfFieldToBeComparedWith = getValues("Password");
    console.log("retype password is", valOfFieldToBeComparedWith);
    return (
      value === valOfFieldToBeComparedWith ||
      FORM.VALIDATION_RETYPE_PWD_MISMATCH
    );
  };

  const onSuccess = (data) => {
    console.log("Data from RQ:", { data });
    console.log("TOKEN:", data.data.token);

    if (step === STEP.SIGNIN) {
      setAuthCtx(data);
      resetForm();
      dialogCtx.setDialog({ open: false, menuIdx: -1 }); //including   setAlert("");
      navigate(from, { replace: true });
    } else if (step === STEP.SIGNUP) {
      dialogCtx.setSeverity("success");
      dialogCtx.setOpenSnackBar(true);
      dialogCtx.setmessageSnackBar(ALERT.SUCCESS_SIGNUP);
      resetForm();
      dialogCtx.setDialog({ open: false, menuIdx: -1 }); //including   setAlert("");
      navigate(from, { replace: true });

      // setAlert(ALERT.SUCCESS_SIGNUP);
      // setStep(STEP.VERIFICATION);
    } else if (step === STEP.VERIFICATION) {
      if (data.status === 201) {
        setAuthCtx(data);
        resetForm();
        dialogCtx.setDialog({ open: false, menuIdx: -1 }); //including   setAlert("");
        navigate(from, { replace: true });
      } else {
        // setAlert(ALERT.SUCCESS_RESEND_CODE);
        dialogCtx.setSeverity("success");
        dialogCtx.setOpenSnackBar(true);
        dialogCtx.setmessageSnackBar(ALERT.SUCCESS_RESEND_CODE);
      }
    } else if (step === STEP.FORGOT_PASSWORD) {
      setForgotPwdToken(data.data.token);

      dialogCtx.setSeverity("success");
      dialogCtx.setOpenSnackBar(true);
      dialogCtx.setmessageSnackBar(ALERT.SUCCESS_RESET_REQUEST);

      setStep(STEP.CONFIRM_FORGOT_PASSWORD);
    } else if (step === STEP.CONFIRM_FORGOT_PASSWORD) {
      dialogCtx.setSeverity("success");
      dialogCtx.setOpenSnackBar(true);
      dialogCtx.setmessageSnackBar(ALERT.SUCCESS_PASSWORD_RESET);
      resetForm();
      setStep(STEP.SIGNIN);

      // dialogCtx.setDialog({ open: false, menuIdx: -1 }); //including   setAlert("");
      // navigate(from, { replace: true });
    }
  };

  const onError = (error) => {
    console.log("error:", { error });
    console.log("error.message:", error?.message);
    console.log("error.response:", error?.reponse);
    console.log("error.request:", !!error.request);

    if (error.message === "Network Error" || !error.response) {
      setAlert(ALERT.ERROR_NETWORK_ERROR);
    } 
    else if (
      error.response.data.message === RESPONSE.ERROR_INCORRECT_PWDUSR
    ) {
      setAlert(ALERT.ERROR_INCORRECT_PWDUSR);
    } else if (error.response.data.message === RESPONSE.ERROR_CODE_MISMATCH) {
      setAlert(ALERT.ERROR_CODE_MISMATCH);
    } else if (
      error.response.data.message === RESPONSE.ERROR_INPUT_VALIDATION
    ) {
      setAlert(ALERT.ERROR_INPUT_VALIDATION);
      console.log("ALERT.ERROR_INPUT_VALIDATION");
    } else if (
      step === STEP.SIGNIN &&
      error.response.data.message === RESPONSE.SUCCESS_USR_NOT_CONFMD
    ) {
      setStep(STEP.VERIFICATION);
      setAlert(ALERT.BLANK);
      reset();
    } else if (error.response.data.message === RESPONSE.ERROR_PWD_ATT_EXC) {
      dialogCtx.setSeverity("warning");
      dialogCtx.setOpenSnackBar(true);
      dialogCtx.setmessageSnackBar(ALERT.ERROR_PWD_ATT_EXC);
      resetForm();
      dialogCtx.setDialog({ open: false, menuIdx: -1 }); //including   setAlert("");
    } else if (error.response.data.message === RESPONSE.ERROR_LIMIT_EXC) {
      dialogCtx.setSeverity("warning");
      dialogCtx.setOpenSnackBar(true);
      dialogCtx.setmessageSnackBar(ALERT.ERROR_LIMIT_EXC);
      dialogCtx.setDialog({ open: false, menuIdx: -1 }); // including   setAlert("");
      resetForm();
      navigate(from, { replace: true });
    }
   else if (error.response.data.message === RESPONSE.ERROR_INVALID_TOKEN) {
      dialogCtx.setSeverity("warning");
      dialogCtx.setOpenSnackBar(true);
      dialogCtx.setmessageSnackBar(ALERT.ERROR_INVALID_TOKEN);
      dialogCtx.setDialog({ open: false, menuIdx: -1 }); // including   setAlert("");
      resetForm();
      navigate(from, { replace: true });
    }
  };

  const {
    isLoading,
    isError,
    reset,
    mutate: sendAuth,
  } = useMutation(
    async (reqConfig) => {
      return await mainHub(reqConfig);
    },
    {
      onSuccess,
      onError,
    }
  );

  React.useEffect(() => {
    if (step === STEP.SIGNIN) setFocus("Email");
    else if (step === STEP.SIGNUP) setFocus("Fullname");
  }, [setFocus, step]);

  const onSubmit = (input, { resendCode = false }) => {
    console.log("submitted form ", JSON.stringify(input));
    console.log("step:", step);
    console.log("resendCode Begins with", resendCode);

    let url = null;
    let withCredentials = false;
    let data = null;

    switch (step) {
      case STEP.SIGNIN:
        url = stepData[STEP.SIGNIN].path;
        data = JSON.stringify({
          email: input.Email,
          pwd: input.Password,
        });
        break;
      case STEP.SIGNUP:
        url = stepData[STEP.SIGNUP].path;
        withCredentials = true;
        data = JSON.stringify({
          name: input.Fullname,
          email: input.Email,
          pwd: input.Password,
        });
        break;
      case STEP.VERIFICATION:
        if (resendCode) {
          data = JSON.stringify({
            email: input.Email,
          });
          url = stepData[STEP.VERIFICATION].resendCodePath;
        } else {
          data = JSON.stringify({
            email: input.Email,
            vcode: input.Vcode,
            pwd: input.Password,
          });
          url = stepData[STEP.VERIFICATION].path;
        }
        break;
      case STEP.FORGOT_PASSWORD:
        data = JSON.stringify({
          email: input.Email,
        });
        url = stepData[STEP.FORGOT_PASSWORD].path;
        break;
      case STEP.CONFIRM_FORGOT_PASSWORD:
        data = JSON.stringify({
          email: input.Email,
          vcode: input.Vcode,
          pwd: input.Password,
        });
        url = stepData[STEP.CONFIRM_FORGOT_PASSWORD].path;
        break;
      default:
        throw Error("Not clear where to go");
    }

    sendAuth({
      method: "post",
      url,
      withCredentials,
      data,
      headers: { Authorization: `Bearer ${forgotPwdToken}` },
    });
  };

  return (
    <>
      {props.titleRef && (
        <DialogTitlePortal
          title={stepData[step].dialogTitle}
          node={props.titleRef}
        />
      )}
      <Stack spacing={0} sx={{ p: 1, m: 1 }}>
        <Alert
          severity={isError ? "error" : "info"}
          icon={isError ? null : false}
          variant={
            isError || alert === ALERT.CODE_COMPLETE ? "standard" : "string"
          }
          sx={{
            width: "100%",
            fontSize: 14,
            mt: 0,
            mb: 2,
            p: 0,
          }}
        >
          {isError || alert === ALERT.CODE_COMPLETE
            ? alert
            : stepData[step].info}
        </Alert>
        {/* <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}> */}
          <Stack
            spacing={step === STEP.VERIFICATION ? 3 : 1}
            sx={{ p: 0, m: 0 }}
          >
            {step === STEP.SIGNUP && (
              <Controller
                render={({ field, fieldState }) => (
                  <TextField
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      clearErrors("Fullname");
                      reset();
                    }}
                    onBlur={field.onBlur}
                    inputRef={field.ref}
                    error={!!fieldState.error}
                    fullWidth
                    id="fullname"
                    label="Full-name"
                    variant="outlined"
                    color="secondary"
                    margin="none"
                    size="normal"
                    helperText={
                      fieldState.error ? fieldState.error.message : " "
                    }
                  />
                )}
                name="Fullname"
                control={control}
                rules={{
                  required: FORM.VALIDATION_FULLNAME,
                }}
              />
            )}
            {(step === STEP.SIGNUP ||
              step === STEP.SIGNIN ||
              step === STEP.FORGOT_PASSWORD) && (
              <Controller
                render={({ field, fieldState }) => (
                  <TextField
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      clearErrors("Email");
                      reset();
                    }}
                    onBlur={field.onBlur}
                    inputRef={field.ref}
                    error={!!fieldState.error}
                    fullWidth
                    id="email"
                    label="Email"
                    variant="outlined"
                    color="secondary"
                    margin="none"
                    size="normal"
                    helperText={
                      fieldState.error ? fieldState.error.message : " "
                    }
                  />
                )}
                name="Email"
                control={control}
                rules={{
                  required: FORM.VALIDATION_EMAIL,
                  pattern: {
                    value: EMAIL_REGEX,
                    message: FORM.VALIDATION_EMAIL_REQ,
                  },
                }}
              />
            )}
            {(step === STEP.SIGNUP ||
              step === STEP.SIGNIN ||
              step === STEP.CONFIRM_FORGOT_PASSWORD) && (
              <>
                <Controller
                  render={({ field, fieldState }) => (
                    <TextField
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("Password");
                        reset();
                      }}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      fullWidth
                      error={!!fieldState.error}
                      id="password"
                      label={
                        step === STEP.CONFIRM_FORGOT_PASSWORD
                          ? "New Password"
                          : "Password"
                      }
                      type="password"
                      variant="outlined"
                      color="secondary"
                      margin="none"
                      size="normal"
                      helperText={
                        step === STEP.SIGNUP ||
                        step === STEP.CONFIRM_FORGOT_PASSWORD
                          ? FORM.PASSWORD_INFO
                          : fieldState.error
                          ? fieldState.error.message
                          : ""
                      }
                    />
                  )}
                  name="Password"
                  control={control}
                  rules={{
                    required: FORM.ENTER_PASSWORD,
                    pattern:
                      step === STEP.SIGNUP ||
                      step === STEP.CONFIRM_FORGOT_PASSWORD
                        ? {
                            value: PWD_REGEX,
                            message: FORM.VALIDATION_PASSWORD,
                          }
                        : null,
                  }}
                />
              </>
            )}
            {step === STEP.CONFIRM_FORGOT_PASSWORD && (
              <>
                <Controller
                  render={({ field, fieldState }) => (
                    <TextField
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("ConfirmPassword");
                        reset();
                      }}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      fullWidth
                      error={!!fieldState.error}
                      id="confirmpassword"
                      label="Confirm New Password"
                      type="password"
                      variant="outlined"
                      color="secondary"
                      margin="none"
                      size="normal"
                      helperText={
                        fieldState.error ? fieldState.error.message : ""
                      }
                    />
                  )}
                  name="ConfirmPassword"
                  control={control}
                  rules={{
                    required: FORM.VALIDATION_RETYPE_PWD,
                    validate: isValueEqual,
                  }}
                />
              </>
            )}
            {(step === STEP.VERIFICATION ||
              step === STEP.CONFIRM_FORGOT_PASSWORD) && (
              <>
                <Stack justifyContent="center">
                  {step === STEP.CONFIRM_FORGOT_PASSWORD && (
                    <Typography
                      sx={{ ml: 1, pb: 0, width: "100%" }}
                      variant="caption"
                      display="block"
                      gutterBottom
                      align="left"
                    >
                      Verification Code
                    </Typography>
                  )}
                  <Controller
                    name="Vcode"
                    control={control}
                    rules={{
                      required: FORM.FIELD_REQUIRED,
                      pattern: {
                        value: CODE_REGEX,
                        message: FORM.VALIDATION_VCODE,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <OtpInput
                        {...field}
                        value={field.value}
                        placeholder="------"
                        ariaLabelOverride="verification code"
                        numInputs={6}
                        hasErrored={!!fieldState.error}
                        shouldAutoFocus={true}
                        inputStyle="inputStyle"
                        containerStyle="container"
                        errorStyle="error"
                        inputRef={field.ref}
                        onChange={(e) => {
                          field.onChange(e);
                          setAlert(ALERT.BLANK);
                          reset();
                        }}
                        onSubmit={handleSubmit((data) => {
                          if (data.Vcode.length === 6) {
                            onSubmit(data, { resendCode: false });
                          }
                        })}
                      />
                    )}
                  />
                </Stack>
              </>
            )}
            <LoadingButton
              onClick={handleSubmit((data) => {
                setAlert(ALERT.BLANK);
                onSubmit(data, {
                  resendCode: false,
                });
              })}
              loading={isLoading}
              fullWidth
              size="medium"
              color="secondary"
              variant="contained"
              sx={{
                mt: 3,
                mb: 3,
                "&.MuiLoadingButton-root": {
                  bgcolor: "secondary.main",
                },
              }}
            >
              {stepData[step].btnText}
            </LoadingButton>
            {step === STEP.VERIFICATION && (
              <Button
                sx={{ mt: 0, pt: 0 }}
                color="secondary"
                size="small"
                onClick={() => {
                  setAlert(ALERT.BLANK);
                  reset();
                  unregister("Vcode")
                  onSubmit(getValues(), {
                    resendCode:true,
                  });
                }}
              >
                Request New Code
              </Button>
            )}
          </Stack>
        {/* </form> */}
        {(step === STEP.SIGNUP || step === STEP.SIGNIN) && (
          <>
            <Divider sx={{ width: "100%", fontSize: 14 }}>or</Divider>
            <Stack
              direction={{ mobilexs: "column", mobile: "row" }}
              spacing={{ mobilexs: 0, mobile: 1 }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                sx={{ mt: 0.3, pb: 0 }}
                variant="button"
                display="block"
                gutterBottom
              >
                {step === STEP.SIGNIN ? FORM.NEED_ACCOUNT : FORM.HAVE_ACCOUNT}
              </Typography>
              <Button
                sx={{ mt: 0, pt: 0 }}
                color="secondary"
                size="small"
                onClick={() => {
                  setAlert(ALERT.BLANK);
                  reset();
                  resetForm();
                  if (step === STEP.SIGNIN) {
                    setStep(STEP.SIGNUP);
                  } else {
                    setStep(STEP.SIGNIN);
                  }
                }}
              >
                {step === STEP.SIGNIN 
                  ? stepData[STEP.SIGNUP].btnText
                  : stepData[STEP.SIGNIN].btnText}
              </Button>
            </Stack>
            {step === STEP.SIGNIN && (
              <>
                <Divider sx={{ width: "100%", fontSize: 14 }}>or</Divider>
                <Stack
                  direction={{ mobilexs: "column", mobile: "row" }}
                  spacing={{ mobilexs: 0, mobile: 1 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{ mt: 0.3, pb: 0 }}
                    variant="button"
                    display="block"
                    gutterBottom
                  >
                    Forgotten password?
                  </Typography>
                  <Button
                    sx={{ mt: 0, pt: 0 }}
                    color="secondary"
                    size="small"
                    onClick={() => {
                      setAlert(ALERT.BLANK);
                      reset();
                      resetForm();
                      setStep(STEP.FORGOT_PASSWORD);
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default SignForm;
