import { useContext } from "react";
import { useQueryData } from "./useRQuery";
import { useSetAuthCtx } from "./useSetAuthCtx";
import AuthContext from "../context/AuthProvider";

const REFRESH_URL = "/auth/refresh";

const useRefreshToken = () => {
  const authCtx = useContext(AuthContext);
  const setAuthCtx = useSetAuthCtx();

  const ucogId = authCtx.ucogId || localStorage.getItem("ucogId");

  const onSuccess = (data) => {
    console.log("New AccessToken from RQ:", { data });
    setAuthCtx(data);
  };

  const { data, isLoading, isSuccess, isError, error, refetch } = useQueryData({
    key: ["refreshToken"],
    reqConfig: { method: "get", url: REFRESH_URL, withCredentials: true },
    onSuccess: onSuccess,
    enabled: !authCtx?.auth?.AccessToken && !!ucogId,
  });

  return { data, isLoading, isSuccess, isError, error, refetch };
};

export default useRefreshToken;
