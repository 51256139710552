import { useContext, useEffect } from "react";
import Home from "./components/Home";
import Layout from "./components/Layout";
import MyProfile from "./components/MyProfile";
import Admin from "./components/Admin";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";
import TokenTest from "./components/TokenTest";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import { Routes, Route } from "react-router-dom";
import { createTheme, colors, ThemeProvider } from "@mui/material";
import NavBar from "./components/NavBar";
import Contact from "./components/Contact";
// import Products from "./components/Products";
import DialogWrapper from "./components/DialogWrapper";
import DialogContext from "./context/DialogProvider";
import Dashboard from "./components/Dashboard";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { StoreAdmin } from "./components/StoreAdmin";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const ROLES = {
  User: 2001,
  Editor: 1984,
  Admin: 5150,
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.blueGrey[500],
      //main: colors.indigo[500],
    },
    secondary: {
      main: "#448aff",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      mobilexs: 0,
      mobile: 385,
      tablet: 600,
      laptop: 1024,
      desktop: 1200,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5*60*1000,
      },
      mutations: {
          retryDelay: 10000,
      },
  },
});

function App() {
  const dialogCtx = useContext(DialogContext);

  useEffect(() => {
    console.log("APP MOUNTED");

    return () => console.log("APP DEMOUNTED");
  }, []);

  console.log("APP called");

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <NavBar></NavBar>
        {/* <DialogWrapper /> */}
        {dialogCtx.dialog.open && <DialogWrapper />}
        <Snackbar
          open={dialogCtx.openSnackBar}
          autoHideDuration={6000}
          onClose={() => dialogCtx.setOpenSnackBar(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            severity={dialogCtx.severity}
            icon={false}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {dialogCtx.messageSnackBar}
          </Alert>
        </Snackbar>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* protect these routes and maintain authentification */}
            <Route element={<PersistLogin />}>
              <Route path="contact" element={<Contact />} />
              <Route path="signin" element={<SignIn />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="home" element={<Home />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="tokentest" element={<TokenTest />} />
              <Route path="/products/*" element={<StoreAdmin />} />
              {/* <Route path="products" element={<Products />} /> */}
              <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
                <Route path="myprofile" element={<MyProfile />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                <Route path="admin" element={<Admin />} />
              </Route>
            </Route>

            {/* catch all */}
            <Route path="*" element={<Missing />} />
          </Route>
        </Routes>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
