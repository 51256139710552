import * as React from 'react';
import { AppBar } from 'react-admin';

const MyAppBar = props => (

    //null
    // <div>This custom layout</div>
    <AppBar {...props} />
);

export default MyAppBar;