import { createContext, useState } from "react";

const AuthContext = createContext({
});


export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [ucogId, setUcogId] = useState(localStorage.getItem("ucogId") || null);

    return (
        <AuthContext.Provider value={{ auth, setAuth,ucogId, setUcogId}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;