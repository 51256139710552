import { Navigate } from "react-router-dom";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CommentIcon from "@mui/icons-material/Comment";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { useQueryData } from "../hooks/useRQuery.js";

const PROFILE_URL = "/myprofile";

const MyProfile = () => {
  console.log("MyProfile called");

  const onSuccess = (data) => {
    console.log("Data from RQ:", { data });
  };

  const { isLoading, error, data, isError, isFetching } = useQueryData({
    key: "myprofile",
    reqConfig: { method: "get", url: PROFILE_URL, /* withCredentials: true */ },
    onSuccess: onSuccess,
  });

  console.log({ isLoading, isFetching, isError, error, data });

  const renderList = (data) => {
    return (
      <Container maxWidth="sm">
        <List sx={{ width: "100%", maxWidth: 400, bgcolor: "white" }}>
          {Object.entries(data.data.Item).map(([key, val], i) => (
            <ListItem
              key={i}
              disableGutters
              secondaryAction={
                <IconButton aria-label="comment">
                  <CommentIcon />
                </IconButton>
              }
            >
              <TextField
                id="standard-start-adornment"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{`${key} :`}</InputAdornment>
                  ),
                  readOnly: true,
                }}
                variant="standard"
                defaultValue={val}
              />
            </ListItem>
          ))}
        </List>
      </Container>
    );
  };

  return (
    <article>
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color="inherit" />
        </Box>
      )}
      <h2>My Data</h2>
      {isError && <p>{error.message}</p>}
      {isError && <Navigate to="/signin" replace={true} />}
      {data ? renderList(data) : !isLoading && <p>No Data</p>}
    </article>
  );
};

export default MyProfile;
