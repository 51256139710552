import { useEffect, useContext } from "react";
import AuthContext from "../context/AuthProvider";
import axios from "axios";
import { useQueryClient } from "react-query";

// const BASE_URL = "https://elb.datapadu.com";
const BASE_URL = "http://localhost:3500";

const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

const useAxios2 = () => {
  const queryClient = useQueryClient();
  const authCtx = useContext(AuthContext);

  console.log("useAxios2 called");

  useEffect(() => {
    console.log("useEffect in useAxios2");

    const requestIntercept = axiosClient.interceptors.request.use(
      (config) => {
        console.log({ config });
        console.log({ authCtx }, "config.url:", config.url);
        if (!config.headers["Authorization"]) {
          const token =
            authCtx?.auth?.AccessToken && config.url !== "/auth/refresh"
              ? authCtx.auth.AccessToken
              : authCtx.ucogId || localStorage.getItem("ucogId");

          config.headers["Authorization"] = `Bearer ${token}`;
          console.log(
            "INSIDE config.headers:",
            authCtx?.auth?.AccessToken,
            config.url
          );
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosClient.interceptors.response.use(
      (response) => {
        console.log({ response });
        return response;
      },
      async (error) => {
        const prevRequest = error?.config;
        console.log("PrevRequest Before:", { prevRequest });
        if (
          error?.response?.data?.message === "TokenExpiredError" &&
          !prevRequest?.sent
        ) {
          prevRequest.sent = true;
          console.log(
            "AccessToken is Expired and previous request not sent yet"
          );

          try {
            console.log("Entered Try block to trigger RefreshToken");
            await queryClient.refetchQueries(
              ["refreshToken"],
              { exact: true },
              { throwOnError: true }
            );

            const data = queryClient.getQueryData(["refreshToken"]);

            console.log("DATA:", data);

            prevRequest.headers[
              "Authorization"
            ] = `Bearer ${data.data.AccessToken}`;
            console.log("PrevRequest After:", { prevRequest });

            return axiosClient(prevRequest);
          } catch (_error) {
            console.log("_ERROR:", _error);
            return Promise.reject(_error);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      console.log("USEAXIOS 2 REMOVED");
      axiosClient.interceptors.request.eject(requestIntercept);
      axiosClient.interceptors.response.eject(responseIntercept);
    };
  }, [authCtx]);

  return axiosClient;
};

export default useAxios2;
