import React, { useContext, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContext from "../context/DialogProvider";
import SlideTransition from "./SlideTransition";
import SignForm from "./SignForm";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const DialogWrapper = () => {
  const ctx = useContext(DialogContext);

  const titleRef = useRef();
  const [node, setNode] = React.useState(null);
  const [refAquired, setRefAquired] = React.useState(false);

  React.useEffect(() => {
    console.log("TitleRef.current:", titleRef.current);
    setRefAquired(true);
    setNode(titleRef.current);
  }, [refAquired]);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown")
    ctx.setDialog({ open: false, menuIdx: -1 });
  };

  return (
    <Dialog
      open={ctx.dialog.open}
      maxWidth={"mobile"}
      fullWidth = {true}
      TransitionComponent={SlideTransition}
      transitionDuration={300}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ margin: 0 }}
    >
      <DialogTitle sx={{ textAlign: "center", m: 0, p: 0.5 }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            m: 0,
            p: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <div ref={titleRef} id="dialog-title"></div>
      </DialogTitle>
      <SignForm titleRef={node}></SignForm>
    </Dialog>
  );
};

export default DialogWrapper;
