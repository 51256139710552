import { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthProvider";
import mainHub from "../api/mainHub";

const useAxios = (straightloading = false) => {
  //const [response, setResponse] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(straightloading); //different!
  const [controller, setController] = useState();
  const ctx = useContext(AuthContext);

  const REFRESH_URL = "/auth/refresh";

  const axiosFetch = async (configObj, transformRespond = null) => {
    const { axiosInstance, requestConfig = {} } = configObj;

    setLoading(true);
    const ctrl = new AbortController();
    setController(ctrl);

    console.log("Auth Context", ctx.auth);

    const requestIntercept = axiosInstance.interceptors.request.use(
      (config) => {
        console.log("Interceptor request called");
        if (!config.headers["Authorization"] && ctx.auth.AccessToken) {
          console.log("Added AccessToken to Auth header bearer");
          config.headers["Authorization"] = `Bearer ${ctx.auth.AccessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => {
        console.log("Interceptor Response succsessfull");
        return response;
      },
      async (error) => {
        const err_message = error.response.data.message;
        console.log("Error from responseIntercept:", err_message);
        const prevRequest = error.config;

        if (err_message === "TokenExpiredError" && !prevRequest?.sent) {
          prevRequest.sent = true;
          console.log("ctx.ucogId:", ctx.ucogId);

          try {
            const response = await mainHub({
              method: "post",
              url: REFRESH_URL,
              withCredentials: true,
              data: JSON.stringify({ ucogId: ctx.ucogId }),
            });
            ctx.setAuth((prev) => {
              console.log("New Accesss Token:", response.data.AccessToken);
              return {
                ...prev,
                roles: response.data?.roles,
                AccessToken: response.data?.AccessToken,
              };
            });

            prevRequest.headers[
              "Authorization"
            ] = `Bearer ${response.data.AccessToken}`;
            return axiosInstance(prevRequest);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
        return Promise.reject(error);
      }
    );

    try {
      const res = await axiosInstance({
        ...requestConfig,
        signal: ctrl.signal,
      });
      console.log("data from custom hook:", res);
      //setResponse(res.data);
      setSuccess(true);
      transformRespond && transformRespond(res.data);
    } catch (err) {
      console.log("err.response:", err.response.data.message);
      setError(err.response.data.message);
      setSuccess(false);
    } finally {
      setLoading(false);
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    }
  };

  useEffect(() => {
    console.log("controller:", controller);

    // useEffect cleanup function
    return () => controller && controller.abort();
  }, [controller]);

  return [success, error, setError, loading, setLoading, axiosFetch];
};

export default useAxios;
