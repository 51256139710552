import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAxios from "../hooks/useAxios";
import mainHub from "../api/mainHub";

const PROFILE_URL = "/myprofile";
const REFRESH_URL = "/auth/refresh";

const TokenTest = () => {
  const [data, setData] = useState();
  const [, , , , , axiosFetch] = useAxios();

  const AccessToken =
  'eyJraWQiOiI5bU9qa21LQ291TnU0SmJwRCs4aHNJaFRsR3hEVEpESnNXY3lLbEpNbXYwPSIsImFsZyI6IlJTMjU2In0.eyJvcmlnaW5fanRpIjoiZDZmYjBmNTctM2QxNy00YWJhLWI2ZTUtYzJkYWI5ZmZmZTNiIiwic3ViIjoiZGYwMTRhMDktOGI2Yy00NjU0LWI1NTQtYTQ2ZDNlYmY4NzRhIiwiZXZlbnRfaWQiOiI3YzMxM2MxYy01NzllLTQ5NmItYWZiNS04MTgwYmM1NzJmZTEiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIiwiYXV0aF90aW1lIjoxNjUxMTYwNzU5LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9sMzJ2Rk9BRDkiLCJleHAiOjE2NTExNjEwNTksImlhdCI6MTY1MTE2MDc1OSwianRpIjoiN2MyMmUxMDItNjhmOS00MTgwLTllZTQtZDU4MGM3MGU5ZWNkIiwiY2xpZW50X2lkIjoiNTJwNDF1MnBycWx0aGc1ZWFoNG1vaHZzNG0iLCJ1c2VybmFtZSI6ImRmMDE0YTA5LThiNmMtNDY1NC1iNTU0LWE0NmQzZWJmODc0YSJ9.RFIdoig5_4x3chfE4zBnil7s9hRCH_RraZk4QDwVII4KVQbJRokUz2jxG5qmSX_o0LhxP4Lsl1NqbQ3jD3JjgNetZLbfbsySXhWul2pkn5W-0U_J0aPth2G6R2GOb5cFxcTe7I24ZYD6YPFH4ph29EJZfEQn89hLqeBOb-_NmIUrSxQSVBfiZgaXtECpIveM81djCNJ1oUptn_LQgBcJ1Nrdlo_jvodL-uxOMa49GdWwBVNzZVBbwAVObppaAXB6Hvccvx-M4nAuWhRoXIFIsJgdg8WEftU7IFQMIe_fKw3IfXgNmijZ0UreIcBFORf8TipFKjMhCv_UFD5U7EGCmQ';

  const RefreshToken =
  'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.okeYTk0XlZYnjCT6fPoij0QHIhqjQt1JY4beICOHltuZhcqHGfIB_OxqW-a-ARMnhBNq7X3nS9de537BIdvKr7UgE2c8lDmWg7fAB-hc-g7nSu7EPQ-AttLRvtrpNgcufQGo2T6dND3UU7JEkZtvVcXk_wuAZC8aJR-Ax8ozq63l6t9NwiiPf2jFJnVWnUrNAoNRPn8KmkdR3hI_Ha_ElWdJDNXxcJG55X7St0wUMbF83H_TFuO1K_Iwgy8Qd1EVk3kvLJxCZJTgXIQznjnVwqKA0hETVdN_JTVhmtwtCjTZbnZSmr9-oUw40hfKGzuzRNmbaAJOby0hJaP_Ht2f7w.a-4zZPjKOW56V8uB.rfh6_jHm6QJeMkUwr8Pf1UlqgFkJjIqINgeV0LA9CKO_-HGv9b_b-bLoMld466OWFJgiMPlEdDb8XyLAX6vpLfhWuC_Y_ezSiyIGSHBJ_s202Go56uYbPNZTY0wb9SU3yRhaKlkohp73d8BpIDSqdzaEe_qCrcPK0jyZ4snNPDCBK4Gq0BQH6uL82ZaCmoApceyzUYk0G-dhEklrkXVQYFGRwv2raL2R0186C1ocrfiqfbWm-bv-hBkNbqET1ELeaqz3OxBsdD7sUPinURpzaylk23vqe6bIBX06CwFqdkx_E5pTFF9-dsB4xUXrT97uI9NcnL-Ns-svPep2Q9oz936y9JgVNoJYZmSe2giclmaA5l7MQooQ3VYdDKOdw53iSUXN226K4k1WdB9bfv-p2mkKYoYqmLgbXU7GRY0H5Z-6h_ymJG97a9xH-ALSKMGkZo7hvaUJDQASDHMMwzGWmZi5Y2OtXVjdJXrlVHcVt7KxUsn2lOXKHFi3XGonVzuSXjJWJueNYn23X9iQqar7c7k6WB1n3q6d_OlH4apcP8xmOsgIhg2g_DScC8_AKD0rGnCvXHKLivRNpSKJF6pivtojUB03F8H3QbAqjKIapSGkCSNDlF9_uxG72z1okChL7_xe5rf3GGofnKr8wnWdBoQOLwx3L_CVleLzbNz9_b95LlSKqGdWDZgIYzfbo96hMG9GovTiXNyNb_eZY-Ax6J1NwlXquYQL5aaaYUyR8TkGPbnXY6lATXv34ze9ulyL2iaI6gKC93YTQA1w5fkmstfmz7vircyLtVfgeI8Ekn7bkPu8KlXP_1AkemH54MbwUZ8-WmO1TQ1TzKRvb7p8enG5rDAWCuXhbwbIdJ_y2eM7dHqUXlTWf9JqFB9lFgZjTf5yolo4nXXJiv_XlTEbz1yWRcqUN7rpDCW445PFtPZcRMRWu7lZivEWMRy0E3qb4lmwyQKXZPbUvHJ5IUPKlBkqvIeUMbvt6UECeRUVLzw3i9LF9019-WFXlzfWOmKM1BFcx0yqqrvDNtdkI267oT4jh6a0QInKBR0xs9-7jcL0O974d4pcmKE_B5s5gufE_jl3ir6W94pnnelCDBUfpunlWmKReinB3BP0aw4VN1UaM3dKgl2F9aZQtdYIiCs7G7150X61E8k3WbLUYRXUhxJJg6Ei2cu7UDfmRDbFzCskJIsme4yNk1_z36WzWsyC_wqac48e9NZPqoN_cigkB5gp87hgJtWGvhe2UMLB8lB0MevclHS8Gjeo7thHRVmXzC09wqEzFyj1Kr52stKAeH4dEp7xYeA8eLO6X5qzYSjO9CHURqKOlSzBtg.hiprXAWIOqT8EpUTguV5fw';

  useEffect(() => {
    let isMounted = true;

    console.log("UseEffect TokenTest");

    const fetch1 = async () => {
      try {
        const response = await mainHub({
          method: "get",
          url: PROFILE_URL,
          withCredentials: true,
          headers: { Authorization: `Bearer ${AccessToken}` },
        });
        console.log("TokenTest: response data:", response);
        setData(response.data);
      } catch (_error) {
        throw _error;
      }
    };
    fetch1();

    const fetch2 = async () => {
      const transformRespond = (data) => {

          console.log("TokenTest Respond Data:", data);

      };
      const ucogId = "df014a09-8b6c-4654-b554-a46d3ebf874a"
      axiosFetch(
        {
          axiosInstance: mainHub,
          requestConfig: {
            method: "post",
            url: REFRESH_URL,
            withCredentials: true,
            data: JSON.stringify({ ucogId }),
          },
        },
        transformRespond
      );
    };

    //fetch1();
    fetch2();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <article>
      <h2>My Data</h2>
      {data ? (
        Object.entries(data.Item).map(([key, val], i) => (
          <div key={i}>
            {key}: {val}
          </div>
        ))
      ) : (
        <p>No Data</p>
      )}
      <div className="flexGrow">
        <Link to="/">Home</Link>
      </div>
    </article>
  );
};

export default TokenTest;
