import React from 'react'
import SignForm from './SignForm';

const SignUp = () => {


    return (
        <>
          <SignForm menuCode={"SignUp"} btnText={"Create New Account"} mt={40}/>
        </>
      );
}

export default SignUp