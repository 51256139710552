import jsonServerProvider from "ra-data-json-server";
import useAxios2 from "./useAxios2";
import { HttpError } from "react-admin";

const useDataProvider = () => {
  const axiosClient = useAxios2();


  const axiosHTTPClient = async (url, options = {}) => {
    console.log("AXIOSCLIENT");
    // console.log({ url }, { options });
    // console.log("options.method:", options.method);
    // console.log("options.body:", options.body);
    
    const axiosHeaders = new Headers();

    const reqConfig = {
      method: options.method ? options.method : "get",
      url: url,
      withCredentials: true,
      data: options.body ? options.body : null,
    };

    try {
      const response = await axiosClient(reqConfig);
      if (response.statusText === "OK") {
        console.log(
          "STATUS:",
          response.status,
          "HEADERS:",
          response.headers,
          "DATA:",
          response.data
        );

        if (response.headers['x-total-count']) 
        {
            axiosHeaders.append('x-total-count', response.headers['x-total-count']);
        }
        return Promise.resolve({
          headers: axiosHeaders,
          json: response.data,
        });
      } else {
        return Promise.reject(
          new HttpError(response.statusText, response.status)
        );
      }
    } catch (error) {
        console.log("Error:", error)
    //   throw new Error ("Something went wrong, please try again later")
      return Promise.reject(
        new HttpError(error.response.statusText, error.response.status)
      );
    }

  };

  //   const dataProvider = jsonServerProvider("http://localhost:3500", httpClient);
  const dataProvider = jsonServerProvider("", axiosHTTPClient);

  return dataProvider;
};

export default useDataProvider;
