import React from 'react'
import SignForm from './SignForm';

const Signin = () => {

    return (
        <>
           <SignForm menuCode={"SignIn"} btnText={"Login"} mt={40}/>
        </>
      );
}

export default Signin