import { useQuery, useMutation } from "react-query";
import useAxios2 from "./useAxios2";

export const useQueryData = ({
  key = "",
  reqConfig = null,
  onSuccess = null,
  onSettled = null,
  enabled = true,
}) => {
  console.log("useQueryData called", { enabled });

  const axiosClient = useAxios2();

  return useQuery(
    key,
    async () => {
      return await axiosClient(reqConfig);
    },
    {
      onSuccess,
      enabled,
      onSettled,
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
      select: (data) => {
        /*No transformation and filtering here */
        return data;
      },
    }
  );
};

export const useMutationData = ({ onSuccess = null, onError = null }) => {
  const axiosClient = useAxios2();

  return useMutation(
    async (reqConfig) => {
      return await axiosClient(reqConfig);
    },
    {
      onSuccess: (data) => {
        onSuccess(data);
        return data;
      },
      onError:(err) => {
        onError(err);
        return err;


      }
    }
  );
};
